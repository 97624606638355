import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = (): React.ReactElement => (
  <Layout>
    <h1>404: Not Found</h1>
  </Layout>
);

export default NotFoundPage;
